import React from "react";
import styled from "styled-components";
import { ColorDisplay } from "~shared/ui";
import { CategoryColor } from "~types";

type ColorListProps = {
  value?: number;
  onChange?: (value: number) => void;
  options: CategoryColor[];
};

export const ColorPicker: React.FC<ColorListProps> = (props) => {
  const { options, onChange, value } = props;

  return (
    <Grid>
      {options.map((option) => (
        <ColorDisplay
          key={option.id}
          selected={option.id === value}
          color={option.hex}
          onClick={() => onChange?.(option.id)}
        />
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, 50px);
  justify-content: space-between;
`;
