import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import { fetchPlot } from "~shared/api/endpoints/Statitstics";
import { PlotQueryOptions } from "./types";
import {
  dayjs,
  fillMonths,
  fillMonthWeeks,
  fillWeekDays,
} from "~shared/helpers";
import { Period } from "~modules/Statistics/types";
import { DateFiller } from "~types";
import { Dayjs } from "dayjs";

export type UseBarsDataQueryParams = {
  minDate: Dayjs;
  maxDate: Dayjs;
  interval: "day" | "month";
  period: Period;
  typeId: number;
};

export const useBarsData = (
  params: UseBarsDataQueryParams,
  options?: PlotQueryOptions
) => {
  const { minDate, maxDate, interval, period, typeId } = params;

  const timezone = dayjs.tz.guess();

  const queryParams = {
    minDate: dayjs(minDate).format(),
    maxDate: dayjs(maxDate).format(),
    interval,
    timezone,
    typeId,
  };

  return useQuery({
    queryKey: [QUERIES.plot, { ...queryParams }],
    queryFn: () => fetchPlot({ ...queryParams }),
    staleTime: 1000 * 60 * 10,
    ...options,
    select: (data) =>
      DATE_FILLER[period]?.(
        { start: dayjs(minDate), end: dayjs(maxDate) },
        data
      ),
  });
};

const DATE_FILLER: Partial<Record<Period, DateFiller>> = {
  week: fillWeekDays,
  month: fillMonthWeeks,
  semester: fillMonths,
  year: fillMonths,
};
