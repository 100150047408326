import React from "react";
import { Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FolderAddOutlined } from "@ant-design/icons";
import { useCategoriesQuery } from "~shared/api/queries";
import { EmptyData, PageLayout } from "~shared/ui";
import { CategoryItem } from "~modules/TransactionsForm/ui/CategoryItem";
import { CIRCLE_MAP } from "~shared/constants";
import styled from "styled-components";

export const CategoriesListPage = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useCategoriesQuery();

  const categories = React.useMemo(
    () =>
      data?.pages
        .flat()
        .map(({ data }) => data)
        .flat() ?? [],
    [data]
  );

  return (
    <PageLayout
      title="Categories"
      padded={false}
      floatingButton={floatingButtonConfig}
      isLoading={isLoading}
    >
      {!categories.length ? (
        <EmptyData
          buttonLabel="Create Category"
          description="Seems like you don't have any categories yet, create one!"
          onClick={() => navigate("/app/categories/new")}
        />
      ) : (
        <Card>
          <Grid>
            {categories.map((category) => (
              <Link key={category.id} to={`/app/categories/${category.id}`}>
                <CategoryItem
                  Icon={CIRCLE_MAP[category.icon.name]}
                  color={category.color.hex}
                  label={category.name}
                />
              </Link>
            ))}
          </Grid>
        </Card>
      )}
    </PageLayout>
  );
};

const Grid = styled.div`
  display: grid;
  gap: 30px 10px;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: center;
`;

const floatingButtonConfig = {
  icon: <FolderAddOutlined />,
  link: "/app/categories/new",
};
