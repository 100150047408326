import React from "react";
import styled from "styled-components";
import { CIRCLE_MAP } from "~shared/constants";
import { ColorDisplay } from "~shared/ui";
import { CategoryIcon } from "~types";

type IconPickerProps = {
  value?: number;
  onChange?: (value: number) => void;
  options: CategoryIcon[];
  color: string;
};

export const IconPicker: React.FC<IconPickerProps> = (props) => {
  const { options, onChange, value, color } = props;

  return (
    <Grid>
      {options.map((option) => (
        <ColorDisplay
          Icon={CIRCLE_MAP[option.name]}
          key={option.id}
          selected={option.id === value}
          color={color}
          onClick={() => onChange?.(option.id)}
        />
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  z-index: 1;
  position: relative;
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, 50px);
  justify-content: space-between;
`;
