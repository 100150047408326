import {
  Airplane,
  ArrowDownLeft,
  ArrowsLeftRight,
  Bag,
  Barbell,
  Bus,
  Calendar,
  ChartLineUp,
  Cigarette,
  Coffee,
  Coins,
  ForkKnife,
  GameController,
  Gift,
  House,
  Icon,
  Money,
  PiggyBank,
  QuestionMark,
  Receipt,
  Storefront,
  TShirt,
} from "@phosphor-icons/react";

export const CIRCLE_MAP: Record<string, Icon> = {
  unknown: QuestionMark,
  calendar: Calendar,
  coffee: Coffee,
  restaurant: ForkKnife,
  weight: Barbell,
  bus: Bus,
  money: Money,
  house: House,
  bills: Receipt,
  cash: Coins,
  supermarket: Storefront,
  cigarette: Cigarette,
  travel: Airplane,
  gift: Gift,
  shirt: TShirt,
  bag: Bag,
  return: ArrowDownLeft,
  safe: PiggyBank,
  controller: GameController,
  transfer: ArrowsLeftRight,
  investment: ChartLineUp,
};
