import React from "react";
import { Flex, List, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useTransactionsSummaryQuery } from "~shared/api/queries/useTransactionsSummaryQuery";
import { SummaryLoadingState } from "./SummaryLoadingState";
import { Date, TransactionListLink, Widget } from "~shared/ui";

export const TransactionsSummary: React.FC = () => {
  const { data, isLoading } = useTransactionsSummaryQuery();

  return (
    <Widget
      title="Expenses"
      isLoading={isLoading}
      LoadingState={<SummaryLoadingState />}
    >
      <List
        dataSource={data?.data}
        renderItem={(item) => (
          <TransactionListLink
            leading={<Date date={item.date} />}
            link={`/app/transactions/${item.id}`}
            transaction={item}
          />
        )}
      />

      <Link to="/app/transactions">
        <SeeAllContainer>
          <Text>See All</Text>
        </SeeAllContainer>
      </Link>
    </Widget>
  );
};

const Text = styled(Typography.Text)`
  color: ${({ theme }) => theme.antd.colorLink};
`;

const SeeAllContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.antd.paddingSM}px;
  border-top: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
`;
