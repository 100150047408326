import React from "react";
import styled from "styled-components";
import { Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "./ui/LoginForm";

export const LoginPage = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    if (localStorage.getItem("access_token")) {
      navigate("/app");
    }
  }, [navigate]);

  return (
    <StyledRow align="middle">
      <Col md={8} sm={5} />
      <Col md={8} sm={14}>
        <StyledCard>
          <LoginForm />
        </StyledCard>
      </Col>
      <Col md={8} sm={5} />
    </StyledRow>
  );
};

const StyledRow = styled(Row)`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.antd.colorBgLayout};
`;

const StyledCard = styled(Card)`
  width: 100%;
`;
