import { dayjs, toApiDate } from "~shared/helpers";
import { PlotData } from "~shared/api/endpoints/Statitstics";
import { Dayjs } from "dayjs";
import { Period } from "./types";
// import { NUMBER_TO_DAY, NUMBER_TO_MONTH } from "./constants";
import { TimeFrame } from "./hooks";

export type DateTransformer = (date: string) => string;

type CreatePlotDataOptions = {
  data?: PlotData[];
};

export const createPlotData = (options: CreatePlotDataOptions) => {
  const { data } = options;

  const dataPlot = data ?? [];

  const dataMax = dataPlot.reduce(
    (max, obj) => (parseFloat(obj.total) > max ? parseFloat(obj.total) : max),
    0
  );

  const totalElementsWithValue = dataPlot.reduce((acc, value) => {
    if (parseFloat(value.total) > 0) {
      acc.push(value.date);
    }
    return acc;
  }, [] as string[]).length;

  const totalAmount = dataPlot.reduce(
    (max, obj) => parseFloat(obj.total) + max,
    0
  );

  const dataAverage = totalAmount / totalElementsWithValue;

  return {
    plot: dataPlot,
    max: dataMax,
    avg: dataAverage,
    total: totalAmount,
  };
};

export const shouldDisableForward = (minDate: Dayjs, period: Period) => {
  const currentDate = dayjs();

  switch (period) {
    case "week":
      return minDate.add(1, "week").isAfter(currentDate);
    case "month": {
      return minDate.add(1, "month").isAfter(currentDate);
    }
    case "semester": {
      return minDate.add(6, "months").isAfter(currentDate);
    }
    case "year": {
      return minDate.add(1, "year").isAfter(currentDate);
    }

    default:
      return false;
  }
};

export const shouldDisableBackwards = (
  maxPeriodDate: Dayjs,
  period: Period,
  minExisitingDate: Dayjs
) => {
  switch (period) {
    case "week":
      return maxPeriodDate.subtract(1, "week").isBefore(minExisitingDate);
    case "month": {
      return maxPeriodDate.subtract(1, "month").isBefore(minExisitingDate);
    }
    case "semester": {
      return maxPeriodDate.subtract(6, "months").isBefore(minExisitingDate);
    }
    case "year": {
      return maxPeriodDate.subtract(1, "year").isBefore(minExisitingDate);
    }

    default:
      return false;
  }
};

export const buildDetailUrl = (frame: TimeFrame, id: number) => {
  return `/app/transactions?filter=date:gte:${toApiDate(
    frame.min
  )}&filter=date:lte:${toApiDate(frame.max)}&filter=categoryId:eq:${id}`;
};
