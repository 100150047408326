import React from "react";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import * as Auth from "~shared/api/endpoints/Auth";

type LoginFormFields = {
  email: string;
  password: string;
};

export const LoginForm: React.FC = () => {
  const [form] = Form.useForm<LoginFormFields>();
  const navigate = useNavigate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: Auth.login,
    mutationKey: ["login"],
  });

  const handleSubmit = async (data: LoginFormFields) => {
    try {
      const response = await mutateAsync({
        email: data.email,
        password: data.password,
      });
      localStorage.setItem("access_token", response.accessToken);
      navigate("/app");
    } catch (e: unknown) {
      const error = e as Error;
      if (error.message === "401") {
        form.setFields([
          {
            name: "email",
            errors: ["Invalid username or password"],
          },
        ]);
      }
    }
  };

  return (
    <Form
      size="large"
      name="login"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
      disabled={isPending}
    >
      <Form.Item<LoginFormFields>
        label="Email"
        name="email"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input placeholder="your@email.com" autoCapitalize="off" />
      </Form.Item>

      <Form.Item<LoginFormFields>
        label="Password"
        name="password"
        rules={[{ required: true, message: "Required" }]}
      >
        <Input.Password placeholder="*******" />
      </Form.Item>

      <ButtonsContainer>
        <Form.Item style={{ width: "100%", margin: 0 }}>
          <Button
            loading={isPending}
            size="large"
            block
            type="primary"
            htmlType="submit"
          >
            Log In
          </Button>
        </Form.Item>
        <Link to="/register" style={{ width: "100%" }}>
          <Button style={{ width: "100%" }} variant="outlined">
            Create Account
          </Button>
        </Link>
      </ButtonsContainer>
    </Form>
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;
