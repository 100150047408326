import { fetchHelper } from "~shared/helpers/fetchHelper";
import { CategoryExpense } from "./types";
import QueryString from "qs";

type UseExpensesQueryParams = {
  minDate: string;
  maxDate: string;
  typeId: number;
};

export const fetchExpenses = (params: UseExpensesQueryParams) => {
  const search = QueryString.stringify(
    {
      minDate: params.minDate,
      maxDate: params.maxDate,
      typeId: params.typeId,
    },
    { encode: true, addQueryPrefix: true }
  );

  return fetchHelper<CategoryExpense[]>({
    url: `/statistics/expenses${search}`,
  });
};
