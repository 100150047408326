import { Icon } from "@phosphor-icons/react";
import styled, { css } from "styled-components";

type CircleItemProps = {
  color: string;
  Icon?: Icon;
  onClick?: () => void;
  selected?: boolean;
};

export const ColorDisplay: React.FC<CircleItemProps> = (props) => {
  const { color, Icon, onClick, selected } = props;

  return (
    <Circle $backgroundColor={color} $selected={selected} onClick={onClick}>
      {!!Icon && <Icon weight="bold" color={color} fill="white" size={24} />}
    </Circle>
  );
};

const Circle = styled.div<{ $backgroundColor: string; $selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${({ theme }) => theme.antd.colorWhite};
  cursor: pointer;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  width: 48px;
  height: 48px;
  position: relative;

  ${({ $selected, $backgroundColor, theme }) =>
    $selected &&
    css`
      &::before {
        z-index: -1;
        /* inset: 0; */
        content: "";
        position: absolute;
        top: -4px;
        left: -4px;
        border-radius: 50%;
        width: 52px;
        height: 52px;
        background-color: ${theme.antd.colorWhite};
        border: 2px solid ${$backgroundColor};
      }
    `};
`;
