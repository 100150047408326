import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import updateLocale from "dayjs/plugin/updateLocale";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);

dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.updateLocale("en", {
  weekStart: 1,
});

export const isBetweeenDates = (test: Dayjs, start: Dayjs, end: Dayjs) => {
  const isAfterStart = test.isAfter(start) || test.isSame(start);
  const isBeforeEnd = test.isBefore(end) || test.isSame(end);

  return isAfterStart && isBeforeEnd;
};

export { dayjs };
