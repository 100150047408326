import { dayjs, isBetweeenDates } from "~shared/helpers";
import { DateFiller } from "~types/DateFiller";

export const fillWeekDays: DateFiller = (limits, data) => {
  const weekStart = limits.start.startOf("week");

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const date = weekStart.add(i, "days").format("YYYY-MM-DD");
    dates.push(date);
  }

  return dates.map((date) => {
    const day = data.find((el) => dayjs(el.date).format("YYYY-MM-DD") === date);

    return {
      date: dayjs(date).format("dd"),
      total: day ? parseFloat(day.total).toFixed(2) : "0",
    };
  });
};

export const fillMonthWeeks: DateFiller = (limits, data) => {
  const daysInMonth = limits.start.daysInMonth();
  const startOfMonth = limits.start.startOf("month");

  const dates = [];
  for (let i = 0; i <= daysInMonth; i = i + 7) {
    const hasDaysLeftOver = i + 7 < daysInMonth;

    const start = startOfMonth.add(i, "days").format("YYYY-MM-DD");
    const end = hasDaysLeftOver
      ? startOfMonth.add(i + 6, "days").format("YYYY-MM-DD")
      : startOfMonth.endOf("month").format("YYYY-MM-DD");
    dates.push({ start, end });
  }

  return dates.map(({ start, end }) => {
    const total = data
      .reduce((acc, value) => {
        if (isBetweeenDates(dayjs(value.date), dayjs(start), dayjs(end))) {
          return acc + parseFloat(value.total);
        }
        return acc;
      }, 0)
      .toFixed(2);

    return {
      date: dayjs(start).format("DD"),
      total,
    };
  });
};

export const fillMonths: DateFiller = (limits, data) => {
  const firstMonth = limits.start.month();
  const lastMonth = limits.end.month();

  const dates = [];
  for (let i = 0; i <= lastMonth - firstMonth; i++) {
    const date = dayjs(limits.start).add(i, "month").format("YYYY-MM");
    dates.push(date);
  }

  return dates.map((date) => {
    const existingEntry = data.find(
      (el) => dayjs(el.date).format("YYYY-MM") === date
    );
    return {
      date: NUMBER_TO_MONTH[dayjs(date).month()],
      total: existingEntry ? existingEntry.total : "0",
    };
  });
};

export const NUMBER_TO_MONTH: Record<number, string> = {
  0: "Ja",
  1: "Fe",
  2: "Ma",
  3: "Ap",
  4: "My",
  5: "Jn",
  6: "Jl",
  7: "Ag",
  8: "St",
  9: "Oc",
  10: "Nv",
  11: "Dc",
};
