import {
  Button,
  ButtonProps,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  TimePicker,
  Select,
} from "antd";
import React from "react";
import { CategoriesModal } from "./ui";
import { TransactionFields } from "./types";
import { Category, SelectOption } from "~types";

type TransactionFormProps = {
  isDisabled: boolean;
  onFinish: (values: TransactionFields) => void | Promise<void>;
  form?: FormInstance<TransactionFields>;
  primaryButtonLabel: string;
  options: {
    categories: Category[];
    types: SelectOption<number>[];
  };
  secondaryButton?: {
    label: string;
    color: ButtonProps["color"];
    variant: ButtonProps["variant"];
    onClick: () => void;
  };
};

export const TransactionForm: React.FC<TransactionFormProps> = (props) => {
  const {
    options,
    form,
    isDisabled,
    onFinish,
    primaryButtonLabel,
    secondaryButton,
  } = props;

  return (
    <Form
      form={form}
      disabled={isDisabled}
      onFinish={onFinish}
      layout="vertical"
      size="large"
    >
      <Form.Item<TransactionFields> label="Title" name="name">
        <Input placeholder="Groceries" />
      </Form.Item>
      <Form.Item<TransactionFields>
        label="Amount"
        name="amount"
        style={{ display: "inline-block" }}
      >
        <InputNumber
          style={{ width: 100 }}
          inputMode="decimal"
          placeholder="100.23"
          parser={(text) => text?.replace(/,/g, ".") ?? ""}
        />
      </Form.Item>
      <Flex justify="flex-start" gap={48}>
        <Form.Item<TransactionFields> label="Date" name="date">
          <DatePicker inputReadOnly placeholder="22/10/2024" />
        </Form.Item>
        <Form.Item<TransactionFields> label="Time" name="time">
          <TimePicker inputReadOnly format="HH:mm" placeholder="18:30" />
        </Form.Item>
      </Flex>
      <Form.Item<TransactionFields> name="categoryId" label="Category">
        <CategoriesModal categories={options.categories} />
      </Form.Item>
      <Form.Item<TransactionFields> name="typeId" label="Type">
        <Select options={options.types} />
      </Form.Item>
      <Form.Item style={{ width: "100%", marginBottom: 12 }}>
        <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
          {primaryButtonLabel}
        </Button>
      </Form.Item>

      {!!secondaryButton && (
        <Form.Item style={{ width: "100%" }}>
          <Button
            color={secondaryButton.color}
            variant={secondaryButton.variant}
            onClick={secondaryButton.onClick}
            block
          >
            {secondaryButton.label}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
