import React from "react";
import styled from "styled-components";
import { Flex, Segmented } from "antd";
import { PageLayout } from "~shared/ui";
import {
  useBarsData,
  useExpensesQuery,
  useStatisticsConfig,
} from "~shared/api/queries/statistics";
import {
  buildDetailUrl,
  createPlotData,
  shouldDisableBackwards,
  shouldDisableForward,
} from "./helpers";
import { ChartsWidget, ExpensesByCategoryWidget, Summary } from "./ui";
import { Period } from "./types";
import { usePeriodData } from "./hooks";
import { dayjs, formatDate } from "~shared/helpers";
import { PERIOD_OPTIONS, PERIOD_TO_INTERVAL, TYPE_OPTIONS } from "./constants";

export const StatisticsPage: React.FC = () => {
  const [type, setType] = React.useState<number>(1);
  const [period, setPeriod] = React.useState<Period>("week");
  const { timeFrame, moveTimeFrame } = usePeriodData({ period });

  const maxDate = formatDate(timeFrame.max);
  const minDate = formatDate(timeFrame.min);

  const { data: expenses } = useExpensesQuery({
    maxDate,
    minDate,
    typeId: type,
  });

  const { data: bars, isLoading } = useBarsData({
    maxDate: timeFrame.max,
    minDate: timeFrame.min,
    interval: PERIOD_TO_INTERVAL[period],
    period,
    typeId: type,
  });

  const { data: config, isLoading: isLoadingConfig } = useStatisticsConfig();

  const barsData = React.useMemo(
    () =>
      createPlotData({
        data: bars,
      }),
    [bars]
  );

  const isDisabledForward = shouldDisableForward(timeFrame.min, period);
  const isDisabledBackwards = shouldDisableBackwards(
    timeFrame.max,
    period,
    dayjs(config?.minDate)
  );

  const handleClickBackwards = () => {
    if (isDisabledBackwards) {
      return null;
    }

    moveTimeFrame("backwards");
  };

  const handleClickForward = () => {
    if (isDisabledBackwards) {
      return null;
    }

    moveTimeFrame("forward");
  };

  return (
    <PageLayout title="Statistics">
      <Wrapper>
        <Graph>
          <Summary
            period={period}
            timeFrame={timeFrame}
            total={barsData.total ?? 0}
          />
          <Segmented
            value={type}
            onChange={(value) => setType(value)}
            block
            options={TYPE_OPTIONS}
          />
          <ChartsWidget
            barsData={barsData ?? []}
            pieData={expenses ?? []}
            isLoading={isLoading || isLoadingConfig}
            onLeftClick={handleClickBackwards}
            onRightClick={handleClickForward}
            disableForward={isDisabledForward}
            disableBackwards={isDisabledBackwards}
          />
          <Segmented
            value={period}
            onChange={(value) => setPeriod(value)}
            block
            options={PERIOD_OPTIONS}
          />
        </Graph>
        <ExpensesByCategoryWidget
          total={barsData.total || NaN}
          buildDetailUrl={(id) => buildDetailUrl(timeFrame, id)}
          data={expenses ?? []}
        />
      </Wrapper>
    </PageLayout>
  );
};

const Wrapper = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginLG}px;
`;

const Graph = styled(Flex).attrs({ vertical: true })`
  gap: ${({ theme }) => theme.antd.marginXS}px;
`;
