import { Card } from "antd";
import { dayjs } from "~shared/helpers";
import React from "react";
import {
  useCategoriesQuery,
  useTransactionMutation,
  useTransactionOptions,
} from "~shared/api/queries";
import { useUserContext } from "~shared/contexts/UserContext";
import { FormLayout } from "~shared/ui";
import { TransactionForm } from "./TransactionsForm";
import { useForm } from "antd/es/form/Form";
import { toFixed2String } from "~shared/helpers";
import { useNavigate } from "react-router-dom";
import { TransactionFields } from "./types";
import { SelectOption } from "~types";

export const TransactionsFormPage: React.FC = () => {
  const [form] = useForm<TransactionFields>();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { data: config } = useTransactionOptions();

  const { mutateAsync, isPending } = useTransactionMutation();
  const { data: categoriesData } = useCategoriesQuery();

  const options = React.useMemo(() => {
    const types: SelectOption<number>[] =
      config?.types.map((type) => ({
        label: type.type,
        value: type.id,
      })) ?? [];

    return {
      categories: config?.categories ?? [],
      types,
    };
  }, [config]);

  React.useEffect(() => {
    if (
      options.categories.length &&
      options.types.length &&
      !form.getFieldValue("categoryId") &&
      !form.getFieldValue("typeId")
    ) {
      form.setFieldsValue({
        categoryId: options.categories[0].id,
        typeId: options.types[0].value,
        date: dayjs(),
        time: dayjs(),
      });
    }
  }, [categoriesData, form, options]);

  const handleOnFinish = async (values: TransactionFields) => {
    const { date, time, amount, ...rest } = values;

    const parsedDate = date.format("YYYY-MM-DD");
    const parsedTime = time.format("HH:mm:ss");
    const dateTime = dayjs(`${parsedDate}T${parsedTime}`);

    const payload = {
      ...rest,
      date: dateTime.utc().format(),
      userId: user!.id,
      amount: toFixed2String(amount),
    };

    await mutateAsync(payload);
    navigate("/app");
  };

  return (
    <FormLayout backUrl="/app" title="Create Transaction">
      <Card>
        <TransactionForm
          form={form}
          primaryButtonLabel="Create"
          options={options}
          isDisabled={isPending}
          onFinish={handleOnFinish}
        />
      </Card>
    </FormLayout>
  );
};
