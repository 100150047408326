import { Col, Layout, Row } from "antd";
import styled from "styled-components";

type LayoutContentProps = {
  padded?: boolean;
};

export const LayoutContent: React.FC<
  React.PropsWithChildren<LayoutContentProps>
> = (props) => {
  const { children, padded } = props;

  return (
    <StyledRow>
      <Col xl={8} lg={6} md={4} sm={0} xs={0} />
      <Col xl={8} lg={12} md={16} sm={24} xs={24}>
        <Content $padded={padded}>{children}</Content>
      </Col>
      <Col xl={8} lg={6} md={4} sm={0} xs={0} />
    </StyledRow>
  );
};

export const Content = styled(Layout.Content)<{ $padded?: boolean }>`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: ${({ theme, $padded }) =>
    $padded ? `${theme.antd.paddingXL}px ${theme.antd.paddingMD}px` : 0};
`;

export const StyledRow = styled(Row)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
