import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "../queries";
import {
  CategoryExpense,
  fetchExpenses,
} from "~shared/api/endpoints/Statitstics";
import { ExtendQueryOptions } from "~shared/api/types";

type UseExpensesQueryParams = {
  minDate: string;
  maxDate: string;
  typeId: number;
};

export const useExpensesQuery = (
  params: UseExpensesQueryParams,
  options?: ExtendQueryOptions<CategoryExpense[]>
) => {
  return useQuery({
    queryKey: [QUERIES.expenses, { ...params }],
    queryFn: () => fetchExpenses(params),
    staleTime: 1000 * 60 * 10,
    ...options,
  });
};
