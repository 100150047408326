import React from "react";
import { Card, Form } from "antd";
import { FormLayout } from "~shared/ui";
import {
  useCategoryMutation,
  useCategorySettingsQuery,
} from "~shared/api/queries";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "~shared/contexts/UserContext";
import { CategoryFormFields } from "./types";
import { CategoryForm } from "./ui/CategoryForm";

export const CreateCategoryPage: React.FC = () => {
  const [form] = Form.useForm<CategoryFormFields>();
  const initRef = React.useRef(false);
  const { user } = useUserContext();
  const { mutateAsync, isPending } = useCategoryMutation();
  const navigate = useNavigate();

  const { data: settings } = useCategorySettingsQuery();

  const onFinish = async (data: CategoryFormFields) => {
    await mutateAsync({ userId: user!.id, ...data });
    navigate("/app/categories");
  };

  React.useEffect(() => {
    if (!initRef.current && settings) {
      form.setFieldsValue({
        colorId: settings.colors[0].id,
        iconId: settings.icons[0].id,
      });
      initRef.current = true;
    }
  }, [settings, initRef, form]);

  return (
    <FormLayout title="Create Category" backUrl="/app/categories">
      <Card>
        <CategoryForm
          primaryButtonLabel="Create"
          disabled={isPending}
          form={form}
          onFinish={onFinish}
          settings={settings}
        />
      </Card>
    </FormLayout>
  );
};
