import { fetchHelper } from "~shared/helpers/fetchHelper";
import QueryString from "qs";

type UseExpensesQueryParams = {
  minDate: string;
  maxDate: string;
};

export const fetchTotalExpenses = (params: UseExpensesQueryParams) => {
  const search = QueryString.stringify(
    {
      minDate: params.minDate,
      maxDate: params.maxDate,
    },
    { encode: true, addQueryPrefix: true }
  );

  return fetchHelper<{ total: number }>({
    url: `/statistics/total${search}`,
  });
};
