export const QUERIES = {
  user: "USER",
  categories: "CATEGORIES-QUERY",
  transactions: "TRANSACTIONS-QUERY",
  transaction: "TRANSACTION-QUERY",
  plot: "PLOT",
  expenses: "EXPENSES",
  totalExpenses: "TOTAL-EXPENSES-QUERY",
  statisticsConfig: "STATISTICS-CONFIG-QUERY",
  transactionOptions: "TRANSACTION-OPTIONS",
};

export const MUTATIONS = {
  transaction: "TRANSACTION-MUTATION",
  updateTransaction: "TRANSACTION-UPDATE-MUTATION",
  updateCategory: "CATEGORY-UPDATE-MUTATION",
  deleteTransaction: "DELETE-TRANSACTION-MUTATION",
  createCategory: "CREATE-CATEGORY-QUERY",
  deleteCategory: "DELETE-CATEGORY-MUTATION",
};
