import { Button, ButtonProps, Form, FormInstance, Input } from "antd";
import React from "react";
import { ColorPicker } from "./ColorPicker";
import { IconPicker } from "./IconPicker";
import { CategoryFormFields } from "../types";
import { CategorySettings } from "~types";

type CategoryFormProps = {
  disabled?: boolean;
  onFinish: (values: CategoryFormFields) => void;
  form: FormInstance<CategoryFormFields>;
  settings?: CategorySettings;
  primaryButtonLabel: string;
  secondaryButton?: {
    label: string;
    color: ButtonProps["color"];
    variant: ButtonProps["variant"];
    onClick: () => void;
  };
};

export const CategoryForm: React.FC<CategoryFormProps> = (props) => {
  const {
    disabled,
    form,
    onFinish,
    primaryButtonLabel,
    secondaryButton,
    settings,
  } = props;

  const colorId = Form.useWatch("colorId", form);

  return (
    <Form
      size="large"
      layout="vertical"
      onFinish={onFinish}
      disabled={disabled}
      form={form}
    >
      <Form.Item label="Category Name" name="name" rules={[{ required: true }]}>
        <Input placeholder="Groceries" />
      </Form.Item>
      <Form.Item name="colorId">
        <ColorPicker options={settings?.colors || []} />
      </Form.Item>
      <Form.Item name="iconId" label="Icon">
        <IconPicker
          options={settings?.icons || []}
          color={
            settings?.colors.find((color) => color.id === colorId)?.hex || ""
          }
        />
      </Form.Item>
      <Form.Item style={{ width: "100%" }}>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {primaryButtonLabel}
        </Button>
      </Form.Item>
      {!!secondaryButton && (
        <Form.Item style={{ width: "100%" }}>
          <Button
            color={secondaryButton.color}
            variant={secondaryButton.variant}
            onClick={secondaryButton.onClick}
            block
          >
            {secondaryButton.label}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
