import { Period, PeriodButton } from "./types";

export const PERIOD_OPTIONS: PeriodButton[] = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Semester", value: "semester" },
  { label: "Year", value: "year" },
];

export const TYPE_OPTIONS: { label: string; value: number }[] = [
  {
    label: "Expenses",
    value: 1,
  },
  { label: "Income", value: 2 },
  { label: "Investments", value: 3 },
  { label: "Divestments", value: 4 },
];

export const PERIOD_TO_INTERVAL: Record<Period, "day" | "month"> = {
  semester: "month",
  year: "month",
  month: "day",
  week: "day",
};
