import { fetchHelper } from "~shared/helpers/fetchHelper";
import { PlotData } from "./types";
import QueryString from "qs";

type FetchPlotDataParams = {
  minDate: string;
  maxDate: string;
  interval: "month" | "day";
  timezone: string;
  typeId: number;
};

export const fetchPlot = (params: FetchPlotDataParams) => {
  const { maxDate, minDate, interval, timezone, typeId } = params;

  const query = QueryString.stringify(
    {
      dateStart: minDate,
      dateEnd: maxDate,
      interval,
      timezone,
      typeId,
    },
    {
      encode: true,
      addQueryPrefix: true,
    }
  );

  return fetchHelper<PlotData[]>({
    url: `/statistics/plot${query}`,
  });
};
