import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchTransactionOptions } from "../endpoints/Transactions";

export const useTransactionOptions = () => {
  return useQuery({
    queryKey: [QUERIES.transactionOptions],
    queryFn: fetchTransactionOptions,
  });
};
