import { Icon } from "@phosphor-icons/react";
import { Flex, Typography } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import { ColorDisplay } from "~shared/ui";

type CategoryItemProps = {
  Icon: Icon;
  color: string;
  label: string;
  onClick?: () => void;
  selected?: boolean;
};

export const CategoryItem: React.FC<CategoryItemProps> = (props) => {
  const { color, Icon, label, onClick, selected } = props;

  return (
    <Container onClick={onClick}>
      <ColorDisplay selected={selected} Icon={Icon} color={color} />
      <Label>{label}</Label>
    </Container>
  );
};

const Container = styled(Flex).attrs({
  align: "center",
  justify: "space-evenly",
  gap: 8,
  vertical: true,
})<{ $selected?: boolean }>`
  ${({ $selected, theme }) =>
    $selected &&
    css`
      border: 1px solid ${theme.antd.colorBorderSecondary};
    `}
`;

const Label = styled(Typography.Text).attrs({ strong: true, ellipsis: true })`
  font-size: 10px;
`;
