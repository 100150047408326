import React from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import { CategoryItem } from "./CategoryItem";
import { CIRCLE_MAP } from "~shared/constants";
import { Category } from "~types";

type CategoriesModalProps = {
  value?: number;
  onChange?: (value: number) => void;
  categories: Category[];
  id?: string;
};

export const CategoriesModal: React.FC<CategoriesModalProps> = (props) => {
  const { id, onChange, value, categories } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const selectedCategory = categories.find((category) => category.id === value);

  return (
    <div id={id}>
      <Button onClick={() => setIsOpen(true)}>
        {selectedCategory?.name ?? "Select Category"}
      </Button>
      <Modal open={isOpen} onCancel={() => setIsOpen(false)} closable={false}>
        <Wrapper>
          <Grid>
            {categories?.map((category) => {
              return (
                <CategoryItem
                  key={category.id}
                  selected={category.id === value}
                  Icon={CIRCLE_MAP[category.icon.name]}
                  label={category.name}
                  color={category.color.hex}
                  onClick={() => {
                    setIsOpen(false);
                    onChange?.(category.id);
                  }}
                />
              );
            })}
          </Grid>
        </Wrapper>
      </Modal>
    </div>
  );
};

const Grid = styled.div`
  display: grid;
  grid-gap: 30px 10px;
  grid-template-columns: repeat(auto-fill, 85px);
  justify-content: space-between;
`;

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.antd.paddingSM}px;
  position: relative;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
`;
