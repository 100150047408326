import React from "react";
import { dayjs } from "~shared/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form } from "antd";
import {
  useDeleteTransactionMutation,
  useTransactionOptions,
  useTransactionQuery,
  useUdateTransactionMutation,
} from "~shared/api/queries";
import { FormLayout } from "~shared/ui";
import { TransactionForm } from "./TransactionsForm";
import { useUserContext } from "~shared/contexts/UserContext";
import useNotification from "antd/es/notification/useNotification";
import { toFixed2String } from "~shared/helpers";
import { TransactionFields } from "./types";
import { SelectOption } from "~types";

export const TransactionEditPage = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm<TransactionFields>();

  const { id } = useParams();
  const numericId = parseInt(id!);

  const { data: config } = useTransactionOptions();
  const { data, isLoading } = useTransactionQuery(numericId);
  const { mutateAsync, isPending } = useUdateTransactionMutation(numericId);

  const { mutateAsync: deleteAsync, isPending: isDeleting } =
    useDeleteTransactionMutation(numericId, {
      onSuccess: () => {
        navigate("/app");
      },
    });

  React.useEffect(() => {
    if (data) {
      const localDate = dayjs.utc(data.date).local();
      form.setFieldsValue({
        amount: toFixed2String(data.amount),
        categoryId: data.category.id,
        typeId: data.type.id,
        date: localDate,
        time: localDate,
        name: data.name,
      });
    }
  }, [data, form]);

  const options = React.useMemo(() => {
    const types: SelectOption<number>[] =
      config?.types.map((type) => ({
        label: type.type,
        value: type.id,
      })) ?? [];

    return {
      categories: config?.categories ?? [],
      types,
    };
  }, [config]);

  const handleOnFinish = async (values: TransactionFields) => {
    const { date, time, amount, ...rest } = values;

    const parsedDate = date.format("YYYY-MM-DD");
    const parsedTime = time.format("HH:mm:ss");
    const dateTime = dayjs(`${parsedDate}T${parsedTime}`);

    const payload = {
      ...rest,
      date: dateTime.utc().format(),
      userId: user!.id,
      amount: toFixed2String(amount),
      id: numericId,
    };

    await mutateAsync(payload);

    api.success({ message: "Transaction updated!" });
  };

  const handleDelete = async () => {
    await deleteAsync();
  };

  return (
    <FormLayout backUrl="/app" title="Create Transaction">
      <Card>
        {contextHolder}
        <TransactionForm
          primaryButtonLabel="Update"
          form={form}
          options={options}
          isDisabled={isLoading || isPending || isDeleting}
          onFinish={handleOnFinish}
          secondaryButton={{
            color: "danger",
            variant: "filled",
            label: "Delete",
            onClick: handleDelete,
          }}
        />
      </Card>
    </FormLayout>
  );
};
