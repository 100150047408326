import { Dayjs } from "dayjs";

export const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const toApiDate = (date: Dayjs): string =>
  date.utc().format(DATE_FORMAT);

export const toUtcDate = (date: Dayjs): string =>
  date.utc().format(DATE_FORMAT);

export const formatDate = (date: Dayjs): string => date.format(DATE_FORMAT);
