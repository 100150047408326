import { PaginatedResponse } from "~shared/api/types";
import { fetchHelper } from "~shared/helpers/fetchHelper";
import qs from "qs";
import {
  FilterDictionary,
  FilterService,
} from "~shared/contexts/FiltersContext/FilterService";
import { Transaction } from "~types";

export const fetchTransactions = (params: {
  page: number;
  limit?: number;
  filters?: FilterDictionary;
}) => {
  const filters = {
    filter: params.filters
      ? FilterService.transformFilters(params.filters)
      : null,
    page: params.page ?? null,
    size: params.limit ?? null,
    sort: "date:desc",
  };

  const search = qs.stringify(filters, {
    arrayFormat: "repeat",
    skipNulls: true,
    encode: false,
    addQueryPrefix: true,
  });

  return fetchHelper<PaginatedResponse<Transaction>>({
    url: `/ledger${search}`,
  });
};
