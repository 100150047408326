import React from "react";
import { Flex, List, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { EUR } from "~shared/helpers/currency";
import { Transaction } from "~types";

type TransactionListLinkProps = {
  link: string;
  transaction: Transaction;
  leading?: React.ReactNode;
};

export const TransactionListLink: React.FC<TransactionListLinkProps> = (
  props
) => {
  const { leading, link, transaction } = props;
  const { amount, name } = transaction;

  const formattedAmount = EUR.format(parseFloat(amount));

  return (
    <StyledItemList>
      <StyledLink to={link}>
        <Content gap="small">
          {leading && leading}
          <Title>
            <Typography.Text>{name}</Typography.Text>
          </Title>
          <Flex flex={0}>
            <Typography.Text strong>{formattedAmount}</Typography.Text>
          </Flex>
        </Content>
      </StyledLink>
    </StyledItemList>
  );
};

const StyledLink = styled(Link)`
  padding: ${({ theme }) =>
    `${theme.antd.paddingSM}px ${theme.antd.paddingLG}px`};
  width: 100%;
`;

const StyledItemList = styled(List.Item)`
  padding: 0 !important;
`;

const Content = styled(Flex).attrs({ flex: 1, align: "center" })`
  text-overflow: ellipsis;
`;

const Title = styled(Flex)`
  flex: 1;
  text-overflow: ellipsis;
`;
