import React from "react";
import { Card, Form } from "antd";
import { FormLayout } from "~shared/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "~shared/contexts/UserContext";
import {
  useCategorySettingsQuery,
  useCategoryUpdateMutation,
  useDeleteCategoryMutation,
} from "~shared/api/queries";
import { useCategoryQuery } from "~shared/api/queries/useCategoryQuery";
import useNotification from "antd/es/notification/useNotification";
import { CategoryFormFields } from "./types";
import { CategoryForm } from "./ui/CategoryForm";

export const UpdateCategoryPage: React.FC = () => {
  const { user } = useUserContext();
  const [form] = Form.useForm<CategoryFormFields>();
  const navigate = useNavigate();
  const [api, contextHolder] = useNotification();
  const { data: settings } = useCategorySettingsQuery();

  const { id } = useParams();

  const numericId = parseInt(id!);

  const { data: category, isLoading } = useCategoryQuery(numericId);
  const { mutateAsync, isPending } = useCategoryUpdateMutation(numericId);
  const { mutateAsync: deleteAsync, isPending: isDeleting } =
    useDeleteCategoryMutation(numericId);

  const onFinish = async (data: CategoryFormFields) => {
    await mutateAsync({ userId: user!.id, ...data });
    api.success({ message: "Transaction updated!" });
  };

  const handleDelete = async () => {
    await deleteAsync();
    navigate("/app/categories");
  };

  React.useEffect(() => {
    if (category) {
      form.setFieldsValue({
        name: category.name,
        colorId: category.color.id,
        iconId: category.icon.id,
      });
    }
  }, [category, form]);

  return (
    <FormLayout title="Update Category" backUrl="/app/categories">
      {contextHolder}
      <Card>
        <CategoryForm
          form={form}
          primaryButtonLabel="Update"
          onFinish={onFinish}
          disabled={isPending || isDeleting}
          settings={settings}
          secondaryButton={{
            color: "danger",
            variant: "filled",
            onClick: handleDelete,
            label: "Delete",
          }}
        />
      </Card>
    </FormLayout>
  );
};
