import React from "react";
import { List } from "antd";
import dayjs from "dayjs";
import { DateTransactions } from "../../types";
import { LoadMoreObserver, TransactionListLink, Widget } from "~shared/ui";
import styled from "styled-components";
import { Transaction } from "~types";

type TransactionsGroupProps = {
  data: DateTransactions[];
  fetchMore: () => void;
  canFetch: boolean;
  linkBuilder: (transaction: Transaction) => string;
};

export const TransactionsList: React.FC<TransactionsGroupProps> = (props) => {
  const { canFetch, data, fetchMore, linkBuilder } = props;

  return (
    <List
      dataSource={data}
      rowKey={(item) => item.date}
      loadMore={canFetch ? <LoadMoreObserver onDisplay={fetchMore} /> : null}
      renderItem={(item) => (
        <StyledWidget title={dayjs(item.date).format("DD MMMM")}>
          {item.transactions.map((transaction, index) => (
            <TransactionListLink
              key={transaction.id}
              transaction={transaction}
              link={linkBuilder(transaction)}
            />
          ))}
        </StyledWidget>
      )}
    />
  );
};

const StyledWidget = styled(Widget)`
  margin-bottom: ${({ theme }) => theme.antd.marginMD}px;
`;
