import { ResponsiveBar } from "@nivo/bar";
import { dayjs } from "~shared/helpers";
import { Widget } from "~shared/ui";
import styled from "styled-components";
import { theme } from "antd";
import { useBarsData } from "~shared/api/queries/statistics";

export const WeeklyChart = () => {
  const { data, isLoading } = useBarsData({
    maxDate: dayjs().endOf("week"),
    minDate: dayjs().startOf("week"),
    interval: "day",
    period: "week",
    typeId: 1,
  });

  const { token } = theme.useToken();

  return (
    <Widget title="Weekly Expenses" isLoading={isLoading}>
      <Container>
        <ResponsiveBar
          data={data ?? []}
          indexBy={"date"}
          keys={["total"]}
          borderRadius={8}
          colors={[`${token.colorPrimary}30`]}
          borderWidth={1}
          labelSkipHeight={1}
          padding={0.3}
          margin={{
            bottom: 35,
            top: 20,
            left: 0,
            right: 0,
          }}
          enableGridY={false}
          enableGridX={false}
        />
      </Container>
    </Widget>
  );
};

const Container = styled.div`
  width: 100%;
  height: 150px;
`;
